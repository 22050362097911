module.exports = {
  blogPostDir: "posts", // The name of directory that contains your posts.
  siteTitle: "portfolio.monotalk.xyz", // Site title.
  siteTitleAlt: "portfolio.monotalk.xyz", // Alternative site title for SEO.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://portfolio.monotalk.xyz", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-material-starter/.
  siteDescription: "kemsakurai's portfilio", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteFBAppID: "106162306804490", // FB Application ID for using app insights
  siteGATrackingID: "UA-47311644-4", // Tracking code ID for google analytics.
  siteGTMID: "GTM-MDJFD5S",
  postDefaultCategoryID: "Tech", // Default category for posts.
  userName: "Ken Sakurai", // Username to display in the author segment.
  userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Japan", // User location to display in the author segment.
  userAvatar: "https://www.gravatar.com/avatar/89a66f750e1c2c4670f5e1702f96b137", // User avatar to display in the author segment.
  userDescription: "Hart wraming dirty Software Development", // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [ 
    {
      label: "GitHub",
      url: "https://github.com/kemsakurai",
      iconClassName: "fa fa-github"
    },
    {
      label: "Twitter",
      url: "https://twitter.com/monotalk_xyz",
      iconClassName: "fa fa-twitter"
    },
    {
      label: "Email",
      url: "mailto:sakurai.kem@gmail.com",
      iconClassName: "fa fa-envelope"
    }
  ],
  copyright: "Copyright © 2017. Monotalk.xyz" // Copyright string for the footer of the website and RSS feed.
};
